import React, { useContext, useEffect, useInsertionEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Container } from '@mui/material';
import blogcontext from '../../context/blog/blogcontext';
import SelectResults from '../SelectResults';
import './BlogPagination.css'


function BlogPagination({ category, setCategoryBlogs, setLoadingPagination }) {

  let Bcontext = useContext(blogcontext)
  const { page, setpage } = Bcontext;
  
  let [totalpages, setTotalpages] = useState(5);

  let paginationInfo
  try {
    paginationInfo = JSON.parse(localStorage.getItem('paginationInfo'))
  } catch (error) {
    setpage(1)
    localStorage.setItem('paginationInfo', JSON.stringify({ page: 1, blogsperpage: 3 }))
  }

  const [numOfBlogsPerPage, setNumOfBlogsPerPage] = useState(paginationInfo?.blogsperpage || 3)

  async function fetchPerPageBlogs(from) {

    const config = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ category })
    }

    let res = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/blog/limited_blogs?from=${from}&numOfBlogsPerPage=${numOfBlogsPerPage}`, config)
    let json = await res.json();
    setCategoryBlogs(json.blogs);
    if (paginationInfo?.page > json.blogs) {
      localStorage.setItem('paginationInfo', JSON.stringify({ page: 1, blogsperpage: 3 }))
      window.location.reload(0)
    }
    window.scroll(0, 0)
  }

  const totalresults = async () => {
    setLoadingPagination(true)
    let res = await fetch(`${process.env.REACT_APP_BACKEND_HOST}/api/blog/totalnumOfBlogs?category=${category}`)
    let json = await res.json()
    setTotalpages(Math.ceil(json.results / numOfBlogsPerPage))
    if (paginationInfo?.blogsperpage > 6) {
      localStorage.setItem('paginationInfo', JSON.stringify({ page: 1, blogsperpage: 3 }))
    }
    setLoadingPagination(false)

  }

  useEffect(() => {
    totalresults();
    fetchPerPageBlogs(((paginationInfo?.page || 1) * numOfBlogsPerPage) - numOfBlogsPerPage);
  }, [category, numOfBlogsPerPage])

  const HandlechangePage = async (e, page) => {
    setpage(page)
    localStorage.setItem('paginationInfo', JSON.stringify({ ...paginationInfo, page }))
    fetchPerPageBlogs((page * numOfBlogsPerPage) - numOfBlogsPerPage);
  }

  return (
    <Container style={{ paddingBottom: "2rem", textAlign: "center" }} maxWidth="lg">
      <Stack style={{ display: 'flex', justifyContent: "space-evenly", flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }} spacing={2}>
        <Pagination page={page} onChange={HandlechangePage} count={totalpages} color="primary" size={window.screen.width > 900 ? 'large' : 'medium'} />
        <SelectResults setpage={setpage} setNumOfBlogsPerPage={setNumOfBlogsPerPage} />
      </Stack>
    </Container>
  );
}

export default BlogPagination
